<template>
  <div class="world orders-page" v-if="orders">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 block">&larr; {{ $t('Back to overview') }}</router-link>
        <h1>{{ $t('My orders') }}</h1>
      </div>
    </div>

    <div class="orders-wrapper">
      <div class="container">
        <div :key="order.id" class="mt-8" v-for="order in orders">
          <h3 class="text-wilmsBlue font-700">{{ order.number }} {{ order.reference }} ({{ dateTime.fromISO(order.order_date).setLocale(activeLocale).toFormat('dd/LL/yyyy') }})</h3>
          <details v-if="order.details.length > 0">
            <summary>{{ $t('Order details') }}</summary>
            <ul class="my-1 bg-wilmsLightGrey p-4">
              <li :key="orderDetail.id" v-for="orderDetail in order.details">
                {{ orderDetail.quantity }}x {{ orderDetail.description }}
              </li>
            </ul>
          </details>
          <ul class="text-sm mt-2">
            <li>{{ $t('Nr.') }}: {{ order.number }}</li>
            <li>{{ $t('Price') }}: {{ $n(parseFloat(order.price), 'currency', 'nl-BE') }}</li>
            <li>{{ $t('Status') }}: {{ $t(order.status) }}</li>
            <li>{{ $t('Actual delivery date') }}: {{ dateTime.fromISO(order.delivery_date).setLocale(activeLocale).toFormat('dd/LL/yyyy') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'

export default {
  data () {
    return {
      orders: [],
      isLoading: false,
      dateTime: DateTime
    }
  },
  async mounted () {
    await this.setDealer()
    this.orders = (await ApiService.fetchOrders(this.dealer.id)).data
    this.orders.sort((a, b) => DateTime.fromISO(a.order_date) < DateTime.fromISO(b.order_date) ? 1 : -1)
  },
  computed: {
    dealers () {
      return this.$store.getters.getCurrentUserDealers
    }
  }
}
</script>
